export const sideMenuList = [
  {
    title: 'Configuration',
    subItems: [
      { title: 'Department', path: 'department' },
      { title: 'Designation', path: 'designation' },
      { title: 'Business Location', path: 'business-location' },
      { title: 'MultiFactor Authentication', path: 'multifactor-auth' },
      { title: 'Password Policy', path: 'password-policy' },
      { title: 'Notification Actions', path: 'notification-actions' },
      { title: 'Notifications', path: 'notifications' },
      { title: 'Role Sequences', path: 'role-sequences' },
    ],
  },
  {
    title: 'Core Hr',
    subItems: [
      { title: 'Employee Type', path: 'employee-type' },
      { title: 'Employee Category', path: 'employee-category' },
      { title: 'Gender', path: 'gender' },
      { title: 'Benefits', path: 'benefits' },
      { title: 'Marital Status', path: 'marital-status' },
      {
        title: 'Dependent Relationship Status',
        path: 'dependent-relationship-status',
      },
      {
        title: 'Document',
        path: 'document',
      },
      {
        title: 'Grade',
        path: 'grade',
      },
      {
        title: 'Contact Type',
        path: 'contact-type',
      },
      {
        title: 'Ticket Frequency',
        path: 'ticket-frequency',
      },
      {
        title: 'Ticket Type',
        path: 'ticket-type',
      },
      {
        title: 'Asset Type',
        path: 'asset-type',
      },
      {
        title: 'Award Type',
        path: 'award-type',
      },
      {
        title: 'Insurance Type',
        path: 'insurance-type',
      },
      {
        title: 'Insurance Policy Type',
        path: 'insurance-policy-type',
      },
      {
        title: 'Training Type',
        path: 'training-type',
      },
      {
        title: 'Time Shift',
        path: 'time-shift',
      },
      {
        title: 'Employee Bank/Exchange',
        path: 'employee-bank',
      },
      {
        title: 'Company Bank/Exchange',
        path: 'company-bank',
      },
      {
        title: 'Leave Type',
        path: 'leave-type',
      },
      {
        title: 'Leave Advance Policy',
        path: 'leave-advance-policy',
      },
      {
        title: 'Leave Accrual Policy',
        path: 'leave-accrual-policy',
      },
      {
        title: 'Leave Encashment Policy',
        path: 'leave-encashment-policy',
      },
      {
        title: 'Comp Off',
        path: 'comp-off',
      },
      {
        title: 'Ticket Accrual Automation',
        path: 'ticket-accrual-automation',
      },
    ],
  },
  {
    title: 'Payroll',
    subItems: [
      {
        title: 'Payroll Type',
        path: 'payroll-type',
      },
      {
        title: 'Salary Configuration',
        path: 'salary-configuration',
      },
      {
        title: 'Additional Benefits',
        path: 'additional-benefits',
      },
      {
        title: 'Addition Type',
        path: 'addition-type',
      },
      {
        title: 'Loan Type',
        path: 'loan-type',
      },
      {
        title: 'Deduction Type',
        path: 'deduction-type',
      },
      {
        title: 'Claim Type',
        path: 'claim-type',
      },
      {
        title: 'Exit Reason',
        path: 'exit-reason',
      },
      {
        title: 'Payout Configuration',
        path: 'payout-configuration',
      },
    ],
  },
];
