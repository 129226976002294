import React from 'react';
import { SearchInput } from '../../../../../../../components';
import SubItems from './SubItems';

import { useLocation, useNavigate } from 'react-router';

import { sideMenuList } from '../../utils';
import { getSubUrl } from '../../utils';

export const defaultParentRoute = '/company-config/';

function SideMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = (seletedItem) => {
    navigate(defaultParentRoute + seletedItem?.path);
  };

  return (
    <>
      <SearchInput
        containerClassName={'w-full max-w-sm'}
        className={
          'w-full max-w-sm py-2 h-10 border-gray-800 border-opacity-25'
        }
        onChange={(e) => {}}
      />
      <div className="flex gap-0 flex-col mt-[11px] overflow-y-auto hidden-scroll max-h-[77dvh]">
        {sideMenuList?.map((menu, menuIndex) => {
          return (
            <div key={menu.title}>
              <p className="text-blue-800 text-md uppercase py-2 px-4 font-semibold tracking-[.05em]">
                {menu.title}
              </p>
              {menu?.subItems?.map((subMenu, subMenuIndex) => {
                return (
                  <SubItems
                    key={subMenu?.title}
                    item={subMenu}
                    isSelected={getSubUrl(pathname) === subMenu?.path}
                    handleClick={handleClick.bind(undefined, {
                      menuIndex: menuIndex,
                      subMenuIndex: subMenuIndex,
                      path: subMenu?.path,
                    })}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SideMenu;
